@import "../../style/App.scss";

// DESKTOP NAVBAR

.logo{
  width: 21rem;
  padding: 5px 10px;
  background: #fff;
  border-radius:70px;
  margin-top:2px;
}

.Navbar {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.3);
  width: 100%;
  // padding: 9px 0;
  transition: 0.3s ease;
  z-index: 100;

  
  .topcontact {
    width: 100%;
    height: 25px;
    background-color: rgba(255, 255, 255, 0.8);
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  }

  #div_top_hypers {
    display: inline;
  }
  #ul_top_hypers li {
    margin-right: 20px;
    display: inline;
  }
  #ul_top_hypers li img {
    vertical-align: middle;
    display: inline;
  }

  .mobile__menu {
    display: none;
    padding: 10px 15px;
    cursor: pointer;
  }
  li a {
    color: #0f5699;
  }
  h1 {
    color: #0f5699;
  }
  .desktop__menu {
    display: block;

    ul {
      li {
        cursor: pointer;
        padding: 2rem 2.7rem;
        margin: 0 1rem;
      }
      li a {
        border-bottom: solid 2px #ffe600;
        padding-bottom: 3px;
      }
      li:last-child {
        margin-right: 0;
        padding-right: 0;
      }
      li:hover {
        color: $main-color;
      }
    }
  }
}
.extraLargeNavbar {
  // padding: 10px 0;
  background-color: transparent;
  box-shadow: none;
  ul {
    li a {
      color: #fff;
    }

    li:hover {
      color: $main-color;
    }
  }
  h1 {
    color: #fff;
  }
}

// MOBILE NAVBAR
.mobile__navbar {
  height: 100vh;
  position: fixed;
  top: 0;
  right: -100%;
  background-color: $black-color;
  z-index: 1000;
  width: 50%;
  transition: 0.4s ease;
  padding: 20px;
  overflow-y: auto;
  background: #312450;

  .mobile__navbar-close {
    padding: 20px;
    margin-right: 80%;
    cursor: pointer;

    img {
      min-width: 30px;
      height: auto;
    }
  }
  .mobile__navbar-logo {
    padding: 10px;
    cursor: pointer;
  }
  .mobile__navbar-menu {
    padding-top: 20%;
    ul {
      li {
        cursor: pointer;
        color: $white-color;
        font-size: 2.5rem;
        padding: 5% 0;
      }
      li:hover {
        color: $main-color;
      }
    }
  }
}
.mobile__open {
  right: 0;
}

// BACKDROP
.backdrop {
  width: 100%;
  height: 100vh;
  background-color: $black-color;
  opacity: 0.8;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 500;
  display: none;
}
.backdrop__open {
  display: block;
}

// MEDIA QUERIES
@media screen and (max-width: 720px) {
  .Navbar {
    .mobile__menu {
      display: block;
    }
    .desktop__menu {
      display: none;
    }
  }
  .mobile__navbar {
    width: 70%;
  }
}

@media screen and (max-width: 575px) {
  .mobile__navbar {
    width: 100%;
  }

  #ul_top_hypers li:last-child {
    display: none;
  }
  #ul_top_hypers li:nth-last-child(2) {
    display: none;
  }
}



@media screen and (max-width: 1093px) {
 
  #ul_top_hypers li:last-child {
    display: none;
  }
}

.nav ul {
  list-style: none;

  li {
    position: relative;
    margin: 3.2em 0;

    a {
      line-height: 5em;
      text-transform: uppercase;
      text-decoration: none;
      letter-spacing: 0.4em;
      color: rgba(#fff, 0.35);
      display: block;
      transition: all ease-out 300ms;
    }

    &.active a {
      color: white;
    }

    &:not(.active)::after {
      opacity: 0.2;
    }

    &:not(.active):hover a {
      color: rgba(#fff, 0.75);
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 0.2em;
      background: black;
      left: 0;
      bottom: 0;
      // background-image: linear-gradient(to right, #5e42a6, #b74e91)
    }
  }
}
