@import "./colors.scss";

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-weight: 500;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}
li {
  list-style: none;
}
/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Montserrat", sans-serif;
  color: $black-color;
  font-size: 1.5rem;
  overflow-x: hidden;

}
.wrapper {
  max-width: 1200px;
  padding: 0 15px;
  height: 100%;
  margin: 0 auto;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-s-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-row-center {
  display: flex;
  align-items: center;
}
.left {
  float: left;
}
.right {
  float: right;
}
a {
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.text-center {
  text-align: center;
}
.margin-center {
  margin: 0 auto;
}
.relative {
  position: relative;
}
.hide {
  display: none;
}
.show {
  display: block;
}
.pointer {
  cursor: pointer;
}
.shadow {
  -webkit-box-shadow: 0px 2px 6px 2px rgba(92, 112, 133, 0.1);
  -moz-box-shadow: 0px 2px 6px 2px rgba(92, 112, 133, 0.1);
  box-shadow: 0px 2px 6px 2px rgba(92, 112, 133, 0.1);
}
/*********************** SPACINGS ***********************/
.padding5 {
  padding: 0.5rem 0;
}
.padding10 {
  padding: 1rem 0;
}
.padding15 {
  padding: 1.5rem 0;
}
.padding20 {
  padding: 2rem 0;
}
.padding30 {
  padding: 3rem 0;
}
.padding40 {
  padding: 4rem 0;
}
.padding60 {
  padding: 6rem 0;
}
/*********************** FONTS ***********************/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}
.weight500 {
  font-weight: 500;
}
.weight800 {
  font-weight: 800;
}
.font9 {
  font-size: 0.9rem;
}
.font10 {
  font-size: 1rem;
}
.font11 {
  font-size: 1.1rem;
}
.font12 {
  font-size: 1.2rem;
}
.font13 {
  font-size: 1.3rem;
}
.font14 {
  font-size: 1.4rem;
}
.font15 {
  font-size: 1.5rem;
}
.font20 {
  font-size: 2rem;
}
.font23 {
  font-size: 2.3rem;
}
.font24 {
  font-size: 2.4rem;
}
.font30 {
  font-size: 3rem;
}
.font35 {
  font-size: 3.5rem;
}
.font40 {
  font-size: 4rem;
}
.font60 {
  font-size: 6rem;
  line-height: 8rem;
}

p {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 3rem;
}
.active-link {
  color: $main-color;
}


.small__button {
  button {
    background-color: $main-color;
    border: 2px solid $main-color;
    padding: 15px 40px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 800;
    margin: 5px 0 30px 0;
    outline: none;
  }
  button:hover {
    border: 2px solid $main-color;
    background-color: transparent;
  }
}

input {
  background-color: $light-color;
  width: 100%;
  max-width: 570px;
  height: 45px;
  border: 0px;
  padding: 0 15px;
  font-size: 12px;
  border: 2px solid $light-color;
  outline: none;
  margin: 5px 0;
}
input:active,
input:focus {
  border: 2px solid $main-color;
}

::placeholder {
  color: $black-color;
  font-size: 12px;
}

textarea {
  background-color: $light-color;
  width: 100%;
  max-width: 570px;
  border: 0px;
  padding: 15px;
  font-size: 12px;
  border: 2px solid $light-color;
  outline: none;
  margin: 5px 0;
}

textarea:active,
textarea:focus {
  border: 2px solid $main-color;
}

::placeholder {
  color: $black-color;
  font-size: 12px;
}


.button {

  .button-link {
     background-color: $main-color;
   
    border: 5px solid $main-color;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 800;
    border-radius: 40px;
  }
  .button-link:hover {
    // border: 2px solid $main-color;
    // background-color: transparent;
   // box-shadow: 0 0 0 1px white, 0 0 0 2px #76ff03, 0 0 0 5px #76ff03, 0 0 0 6.5px white, 0 0 0 7px #76ff03;
  
   border-width: 5px;
   border-radius: 100px;
    background: none;
    color: $main-color;
    transition: all 0.7s;
  }
}